import type { TFunction } from 'i18next';
export const COMPANY_BANK_ACCOUNT_STATUS_ENUM = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
} as const;

export type COMPANY_BANK_ACCOUNT_STATUS_ENUM =
  (typeof COMPANY_BANK_ACCOUNT_STATUS_ENUM)[keyof typeof COMPANY_BANK_ACCOUNT_STATUS_ENUM];

export const getCompanyBankAccountStatusI18n = (
  t: TFunction,
): Record<COMPANY_BANK_ACCOUNT_STATUS_ENUM, string> => ({
  [COMPANY_BANK_ACCOUNT_STATUS_ENUM.OPEN]: t('Open'),
  [COMPANY_BANK_ACCOUNT_STATUS_ENUM.CLOSED]: t('Closed'),
});

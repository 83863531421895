/**
 * @generated SignedSource<<c6734bd25bf9b38fff1ff85950010202>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuConfirmDefaultAccountModal_account$data = {
  readonly id: string;
  readonly isDefault: boolean;
  readonly " $fragmentType": "UserMenuConfirmDefaultAccountModal_account";
};
export type UserMenuConfirmDefaultAccountModal_account$key = {
  readonly " $data"?: UserMenuConfirmDefaultAccountModal_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuConfirmDefaultAccountModal_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuConfirmDefaultAccountModal_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefault",
      "storageKey": null
    }
  ],
  "type": "CompanyBankAccount",
  "abstractKey": null
};

(node as any).hash = "0332eba8a87306aa6d3ec4fc6a1f6c80";

export default node;

/**
 * @generated SignedSource<<dfd25a6b5467e6ca50b7e4bcd4aaec65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useChatwoot_user$data = {
  readonly _id: string;
  readonly company: {
    readonly id: string;
    readonly nameFriendly: string | null | undefined;
  } | null | undefined;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly phoneNumbers: ReadonlyArray<string | null | undefined> | null | undefined;
  readonly userMaster: {
    readonly chatWootAuth: string | null | undefined;
    readonly emails: ReadonlyArray<{
      readonly email: string | null | undefined;
      readonly wasVerified: boolean | null | undefined;
    } | null | undefined> | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
    readonly phoneNumbers: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly taxID: {
      readonly taxID: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "useChatwoot_user";
};
export type useChatwoot_user$key = {
  readonly " $data"?: useChatwoot_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useChatwoot_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useChatwoot_user"
};

(node as any).hash = "80a51f50eb67b1d87dd214460c250673";

export default node;
